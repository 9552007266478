@import '../../styles/variables';

.Placeholder {
  .Placeholder__Icon {
    position: absolute;
    top: -2px;
    right: -4px;
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }
}

:global(.ant-select-open) {
  .Placeholder__Icon {
    transform: rotate(180deg);
  }
}

/**********************
 * Date Filter
 **********************/

.DateFilter {
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid $medium-grey;
  margin-top: 5px;
  border-radius: $border-radius;
  transition: border-color 0.2s linear;

  &:hover {
    border-color: $blue;
  }

  > span {
    height: 45px;
    color: $dark-grey;
  }

  .CalendarIcon {
    top: 18px;
    right: 6px;
    width: 20px;
    height: 20px;
  }

  :global(.ant-calendar-picker-clear) {
    right: 32px;
  }

  :global(.ant-input) {
    height: 42px;
    padding: 0 0 0 $spacing-small;
    color: $dark-greyish-blue;
    font-size: 13px;
    text-align: left;

    &::placeholder {
      color: $blue-grey;
    }

    &:-ms-input-placeholder {
      color: $blue-grey;
    }
  }
}

.DateFilter__Dropdown {
  z-index: 1500;
}

/**********************
 * Date Range Filter
 **********************/

.DateRangeFilter {
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid $medium-grey;
  margin-top: 5px;
  border-radius: $border-radius;
  transition: border-color 0.2s linear;

  &:hover {
    border-color: $blue;
  }

  > span {
    height: 45px;
    color: $dark-grey;
  }

  .CalendarIcon {
    top: 18px;
    right: 6px;
    width: 20px;
    height: 20px;
  }

  :global(.ant-calendar-picker-clear) {
    right: 32px;
  }

  :global(.ant-calendar-range-picker-separator) {
    vertical-align: baseline;
  }

  :global(.ant-calendar-picker-input.ant-input) {
    padding: 0;
  }

  :global(.ant-calendar-range-picker-input) {
    width: 40%;
    padding: 0 0 0 $spacing-small;
    color: $dark-greyish-blue;
    font-size: 13px;
    text-align: left;

    &::placeholder {
      color: $blue-grey;
    }

    &:-ms-input-placeholder {
      color: $blue-grey;
    }
  }
}

.DateRangeFilter__Dropdown {
  z-index: 1500;
}

/**********************
 * Base Select Filter
 **********************/

.SelectFilter {
  position: relative;
  width: 100%;
  margin-top: 3px;
  cursor: pointer;

  :global(.ant-select-search.ant-select-search--inline) {
    margin-top: 0;
  }

  :global(.ant-select-search__field__placeholder) {
    right: $spacing-small / 2;
    left: $spacing-small;
    color: $blue-grey;
    font-size: 13px;
    line-height: 20px;
  }

  :global(.ant-select-selection) {
    height: auto;
    min-height: 46px;
    max-height: 200px;
    color: $blue-grey;
    font-size: 13px;
    overflow-y: auto;

    .ant-select-search {
      line-height: 14px;
    }
  }
}

.SelectFilter__rendered {
  :global(.ant-select-selection) {
    padding-right: 25px;
      margin-top: 3px;
      margin-right: 0;
      line-height: 14px;

      &::after {
        content: '';
      }
  }
}

.SelectFilter__choice {
  :global(.ant-select-selection) {
    background-color: #e2ebf3;
    color: $dark-greyish-blue;
    font-size: 13px;
  }
}

.SelectFilter__choice__remove {
  :global(.ant-select-selection) {
    color: $light-blue;
  }
}

.SelectFilter__placeholder {
  :global(.ant-select-selection) {
    color: $blue-grey;
    font-size: 13px;
  }
}

.SelectFilter-selected-value {
  :global(.ant-select-selection) {
    color: $dark-greyish-blue;
    line-height: 30px;
  }
}

.SelectFilter__Dropdown {
  :global(.ant-select-tree-title) {
    font-size: 13px;
    font-weight: 500;
  }

  :global(.ant-select-tree-node-content-wrapper span) {
    color: $dark-greyish-blue;
    font-size: 13px;
    font-weight: 500;
  }

  :global(.ant-select-tree) {
    margin-top: 0;

    span {
      font-size: 13px;
    }

    li {
      margin: 2px 0;
    }

    :global(span.ant-select-tree-checkbox + .ant-select-tree-node-content-wrapper) {
      width: calc(100% - 40px);
    }

    :global(.ant-select-tree-node-content-wrapper:hover) {
      background-color: rgba($light-blue, 0.2);
    }
  }

  &--noChildItems {
    :global(.ant-select-tree) {
      padding: 0;
    }

    :global(.ant-select-tree-switcher-noop) {
      position: relative;
      display: none !important;
      width: 0;
      padding: 0;
    }

    :global(.ant-select-tree-checkbox) {
      margin-left: 6px !important;
    }

    :global(.ant-select-tree li .ant-select-tree-node-content-wrapper) {
      width: 100%;
    }
  }
}

/**********************
 * Multi-Select Filter
 **********************/

.MultiSelectFilter {
  :global(.ant-select-selection--multiple) {
    padding: 0;
  }
}

/**********************
 * Single Select Filter
 **********************/

.SingleSelectFilter {
  .Placeholder__Icon {
    right: -7px;
  }

  :global(.ant-select-selection__rendered) {
    display: flex;
    align-items: center;
  }
}

/**********************
 * Base Input Filter
 **********************/

.InputGroup {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  .Split {
    display: flex;
    width: 30px;
    height: 100%;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    color: $medium-grey;
  }
}

.InputFilter {
  position: relative;
  width: 100%;
  height: 42px;
  border: 1px solid $medium-grey;
  margin-top: 5px;
  border-radius: $border-radius;
  font-size: 13px;

  &--Split {
    flex: 1;
  }

  :global(.ant-input) {
    padding: 0 0 0 $spacing-small !important;

    &::placeholder {
      color: $blue-grey;
    }

    &:-ms-input-placeholder {
      color: $blue-grey;
    }
  }
}

.MultiInputFilter {
  position: relative;
  width: 100%;
  border: 1px solid $medium-grey;
  margin-top: 0;
  border-radius: $border-radius;
  font-size: 13px;

  textarea {
    padding: $spacing-small / 2 0 0 $spacing-small !important;
    border: none;
    resize: none;

    &::placeholder {
      color: $blue-grey;
    }

    &:-ms-input-placeholder {
      color: $blue-grey;
    }
  }
}
