@import '../../styles/variables';

.Container {
  display: inline-flex;
  align-items: center;
}

.Pointer {
  cursor: pointer;
}

.Tooltip__Info {
  composes: Pointer;
  position: relative;
  display: inline-flex;
  margin-left: $spacing-small / 2;
  color: transparent;

  &:hover {
    svg {
      path {
        fill: $blue !important;
      }
    }
  }

  svg {
    path {
      fill: $blue-grey !important;
    }
  }
}
