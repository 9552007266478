@import '../../styles/variables';
@import '../../styles/mixins';

.ButtonGroupToggle {
  white-space: nowrap;

  &.Disabled {
    .Option {
      @include disabled;
    }
  }
}

.Label {
  display: inline-block;
  padding: 11px 13px;
  border: 1px solid $medium-grey;
  background: $white;
  border-radius: 3px 0 0 3px;
  color: $dark-grey;
  font-family: $font-family;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.Option {
  display: inline-block;
  padding: 11px 13px;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: $medium-grey;
  background: $white;
  color: $dark-grey;
  cursor: pointer;
  font-family: $font-family;
  font-size: 12px;
  font-weight: 500;

  &:focus {
    outline: 0;
  }

  &--active {
    border: 1px solid $blue;
    color: $blue;
  }

  &:first-child {
    border-left-width: 1px;
  }

  &:last-child {
    border-radius: 0 3px 3px 0;
  }
}
