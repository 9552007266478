@import '../../styles/variables';

$divider-text-width: 75px;

.TextDivider {
  display: inline-flex;
  height: 15px;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-between;
  margin: $spacing-small * 2 0;

  .TextDivider__Seperator {
    display: flex;
    height: 1px;
    flex: 1;
    border-bottom: 1px solid $medium-grey;
  }

  .TextDivider__Text {
    width: $divider-text-width;
    text-align: center;
  }
}
