@import '../../styles/variables';

.AvatarSet {
  display: inline-flex;
  align-items: center;

  .Avatar {
    position: relative;
    border: 3px solid $white;
    border-radius: 60px;
  }

  .TruncateText {
    position: relative;
    left: -14px;
    color: #6d7993;
    font-family: 'Roboto Medium', sans-serif;
    font-size: 13px;
    line-height: 15px;
  }
}
