@import '../../styles/variables';
@import '../../styles/mixins';

.Content {
  overflow: auto;
  min-width: 245px;
  max-height: 307px;
  box-sizing: border-box;
  background: $white;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:focus {
    outline: none;
  }
}

.Trigger {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 $spacing-small 0 $spacing-small;
  padding-right: 35px;
  border: 1px solid $medium-grey;
  background: $white;
  border-radius: $border-radius;
  color: $dark-grey;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.Disabled {
    @include disabled;

    &:focus,
    &:hover {
      border-color: $medium-grey;
      box-shadow: none;
    }
  }

  &:focus,
  &:focus-within {
    border: 1px solid $blue;
    box-shadow: 0 0 0 2px $blue-ring;
    outline: none;
  }

  &.Error {
    &:focus,
    &:focus-within {
      border: 1px solid $red;
      box-shadow: 0 0 0 2px $red-ring;
      outline: none;
    }

    .Trigger--active {
      svg {
        * {
          stroke: $red;
        }
      }
    }
  }

  &.Warning {
    &:focus,
    &:focus-within {
      border: 1px solid $orange;
      box-shadow: 0 0 0 2px $orange-ring;
      outline: none;
    }

    .Trigger--active {
      svg {
        * {
          stroke: $orange;
        }
      }
    }
  }

  &--active {
    composes: Trigger;

    .TriggerIcon {
      transform: rotate(180deg) translate(7px, -2px);
      svg {
        * {
          stroke: $blue;
        }
      }
    }
  }
}

.TriggerIcon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  transform: translate(-7px, 0px);

  svg {
    transition: all 0.1s ease-in-out;

    * {
      stroke: $dark-grey;
    }
  }
}

.Option {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  padding: 6px 10px;
  color: $dark-blue;
  cursor: pointer;
  font-family: $paragraph-font-family;
  font-size: 13px;
  line-height: 24px;
  text-overflow: ellipsis;

  .Label {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:hover {
    background: #ebf3fc;
  }
}

.Selected {
  display: flex;
  justify-content: space-between;
}

.CurrentBadge {
  height: 24px;
  padding: 0 2px;
  margin: auto 0;
  line-height: 22px;
}

.OptionsGroup {
  margin-top: 10px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  &:not(.HasLabel).IsSingle {
    margin-top: 0;
  }
}

.OptionsGroupLabel {
  padding: 0 10px 0;
  text-transform: uppercase;
}
