@import '../../styles/variables';

.Field {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 $spacing-large;
  text-align: initial;

  &--Disabled {
    opacity: 0.5;
  }

  .InputContainer {
    position: relative;
    border: 1px solid $medium-grey;
    background-color: $white;
    border-radius: $border-radius;

    &:not(.InputContainer--borderless) {
      :global(.ant-input) {
        border: 0 !important;
      }

      &:focus,
      &:focus-within {
        border-color: $blue;
        box-shadow: 0 0 0 2px $blue-ring;
      }

      &.Active {
        border-color: $blue;
      }

      &.Error {
        border-color: $red;

        &:focus,
        &:focus-within {
          box-shadow: 0 0 0 2px $red-ring;
        }
      }

      &.Warning {
        border-color: $orange;

        &:focus,
        &:focus-within {
          box-shadow: 0 0 0 2px $orange-ring;
        }
      }
    }

    &--borderless {
      border: none;
      background-color: initial;
    }
  }

  label {
    display: block;
    margin-bottom: $spacing-small / 3;
  }

  .Description {
    display: block;
    color: $dark-grey;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    text-transform: none;
  }
}

.Field--success {
  &::after {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 25px;
    height: 25px;
    animation: stretch 3s ease-out 0s alternate infinite none running;
    content: url(./assets/email-success.svg);
  }
}

.Field--failure {
  &::after {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 25px;
    height: 25px;
    animation: stretch 3s ease-out 0s alternate infinite none running;
    content: url(./assets/email-failure.svg);
  }
}

.HeadingWrapper {
  display: flex;
  justify-content: space-between;

  .Label {
    display: flex;
    min-height: 24px;
    align-items: center;

    .Required {
      margin-left: 4px;
      color: $red;
    }
  }
}

.HeadingOptional {
  position: relative;
  top: 3px;
  color: $blue-grey;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  text-transform: initial;
}
