@import '../../styles/variables';

.IconButtonGroup {
  display: flex;
  justify-content: space-around;
}

.IconButton {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: $dark-grey;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  outline: none;

  svg {
    transform: translate(-7px, 0);
    * { stroke: $dark-grey; }
  }

  &:hover {
    color: $blue;

    svg {
      * { stroke: $blue; }
    }
  }

  &--delete:hover {
    color: $red;

    svg {
      * { stroke: $red; }
    }
  }

  &--disabled:hover {
    color: $dark-grey;
    cursor: not-allowed;

    svg {
      * { stroke: $dark-grey; }
    }
  }
}
