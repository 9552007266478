@import '../../styles/variables';

.Note__Block {
  .Note {
    display: inline-flex;
    width: inherit;
    min-width: 300px;
    min-height: 35px;
    align-items: center;
    padding: 0 $spacing-small;
    margin: 0;
    background-color: rgba($blue-grey, 0.15);
    border-radius: $border-radius;
    color: $dark-greyish-blue;
    font-size: 12px;
  }

  &.fullWidth {
    width: 100%;

    blockquote {
      width: 100%;

      div, span {
        width: 100%;
      }
    }
  }
}
