@import '../../styles/variables';

.CheckboxSetField {
  .Option {
    position: relative;
    display: inline-block;
    padding: 10px 30px;
    border: 1px solid $medium-grey;
    margin: 0 10px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

    &--Selected {
      border: 1px solid $blue;

      &::after {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 25px;
        height: 25px;
        padding: 5px;
        border: 1px solid $blue;
        background: $white;
        border-radius: 15px;
        color: $blue;
        content: '✓';
        line-height: 15px;
        text-align: center;
      }
    }
  }
}
