@import '../../styles/variables';

:global(.ant-breadcrumb-separator) {
  position: relative;
  top: 7px;
  margin: 0 4px;
}

.BreadcrumbItem {
  display: inline-block;
  padding: 4px 10px;
  background-color: rgba($light-blue, 0.2);
  border-radius: $border-radius;

  &:focus {
    outline: 0;
  }

  &--last {
    cursor: default;
  }

  &--clickable {
    cursor: pointer;
  }

  .Text {
    color: #000;
  }
}

.Separator {
  color: $blue-grey;
}
