@import '../../styles/variables';

.Warning {
  display: flex;
  padding: 6px $spacing-large 6px $spacing-large;
  border: 1px solid;
  border-radius: 3px;

  &--grey {
    border-color: $dark-grey;
    color: $dark-grey;

    svg {
      * {
        stroke: $dark-grey;
      }
    }
  }

  &--orange {
    border-color: $orange;
    background-color: $orange-10alpha;
    color: $orange;

    svg {
      * {
        stroke: $orange;
      }
    }
  }
}

.Icon {
  position: relative;
  top: 2px;
  display: flex;
}

.Content {
  display: inline-block;
  padding: 0 0 0 $spacing-small;
  font-family: $font-family;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;

  a {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: underline;
  }
}
