@import '../../styles/variables';

.FieldError {
  display: none;
  align-items: center;
  margin-top: $spacing-small / 2;

  &.Heavy {
    padding: 0.5px 5px;
    border-radius: $border-radius;
  }

  &--showing {
    display: inline-flex;
  }

  &.Error {
    color: $red;

    &.Heavy {
      background-color: rgba($red, 0.15);

      .Icon {
        color: $red !important;
      }
    }
  }

  &.Warning {
    color: $orange;

    &.Heavy {
      background-color: rgba($orange, 0.15);

      .Icon {
        color: $orange !important;
      }
    }
  }

  .Icon {
    display: flex;
    width: auto;
    align-self: flex-start;
    margin: 0 3px 0 -3px;
  }

  .Text {
    display: flex;
  }
}
