@import '../../styles/variables';

.Button {
  border: none;
  background-color: rgba($white, 0.15);

  &:hover,
  &:active,
  &:focus {
    background-color: rgba($white, 0.15);
  }
}

.ButtonIcon {
  transform: rotate(0deg);
  transition: transform 0.2s linear;

  &.Active {
    transform: rotate(180deg);
  }

  &.HasChildren {
    margin-right: -8px;
  }
}

.Dropdown {
  min-width: 200px;
  padding: 5px 0;
  border: 1px solid rgba(#1d1d28, 0.07);
  margin: 0;
  background: $white;
  border-radius: $border-radius;
  box-shadow: 0 3px 30px rgba($dark-blue, 0.07);
  list-style: none;
}

.DropdownItem {
  position: relative;
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 $spacing-small;
  margin-bottom: 5px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: rgba($light-blue, 0.1);

    .DropdownItemIcon {
      color: $blue;
    }
  }
}

.DropdownItemIcon {
  display: flex;
  align-items: center;
  margin-right: 12px;
  color: $blue-grey;
}

.DropdownItemText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
