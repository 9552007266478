@import '../../styles/variables';

.TimePicker {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;

  div {
    width: 100%;
  }

  input {
    padding: 9px $spacing-small 8px;
    border: none;
    box-shadow: none;

    &::placeholder {
      color: $blue-grey;
    }
  }

  :global(.ant-time-picker-input:focus) {
    box-shadow: none;
  }
}

.TimePicker__Popup {
  :global(.ant-time-picker-panel-input-wrap) {
    height: 40px;
  }

  :global(.ant-time-picker-panel-input) {
    height: 100%;
  }

  :global(li.ant-time-picker-panel-select-option-selected) {
    background-color: rgba(133, 147, 243, 0.1) !important;
  }

  :global(.ant-time-picker-panel-select li:focus) {
    color: $purple;
  }

  :global(.ant-time-picker-panel-select li:hover) {
    background-color: rgba(133, 147, 243, 0.1) !important;
  }

  :global(.ant-time-picker-panel-combobox) {
    // Fix the padding on 15 min increments to avoid scrolling
    :global(.ant-time-picker-panel-select:nth-child(2)) {
      ul {
        padding: 0 0 64px;
      }
    }

    // Fix the padding on AM/PM increments to avoid scrolling
    :global(.ant-time-picker-panel-select:nth-child(3)) {
      ul {
        padding: 0 0 125px;
      }
    }
  }
}
