@import '../../styles/variables';

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.FullWidth {
    width: 100%;
  }

  &.Disabled {
    border-color: $medium-grey;
    background-color: $medium-grey;
    box-shadow: none;
    color: $white;
    text-shadow: none;

    &:active,
    &:hover,
    &:focus {
      border-color: $medium-grey;
      background-color: $medium-grey;
      box-shadow: none;
      color: $white;
      text-shadow: none;
    }
  }
}

:global(.ant-btn-dashed) {
  border-width: 2px;
}

.AsLinkButton {
  height: auto;
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: $blue;
    text-decoration: underline;
  }
}

.ConfirmButton {
  border-color: $green;
  background-color: $green;

  &:hover,
  &:focus,
  &:active {
    border-color: $dim-green;
    background-color: $dim-green;
  }
}

.DangerButton {
  border-color: $red;
  background-color: $red;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    border-color: $dim-red;
    background-color: $dim-red;
  }
}

.WarningButton {
  border-color: $orange;
  background-color: $orange;

  &:hover,
  &:focus,
  &:active {
    border-color: $dim-orange;
    background-color: $dim-orange;
  }
}

.InvisibleButton {
  border: none;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    border: none;
  }
}

.ToggledButton {
  border-color: $dark-grey;
  background-color: $dark-grey;

  &:hover,
  &:focus,
  &:active {
    border-color: $dark-grey;
    background-color: $dark-grey;
  }
}

.AlternativeButton {
  border-color: $light-blue;
  background-color: $light-blue;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    border-color: $blue-grey;
    background-color: $blue-grey;
    color: $white;
  }
}

$upload-blue: rgba($blue, 0.7);

.UploadButton {
  border-color: $upload-blue;
  color: $upload-blue;
}

$reset-blue-grey: #7299c2;
.ResetButton {
  border-color: $reset-blue-grey;
  background-color: $reset-blue-grey;
  color: white;
}

.Icon {
  display: inline-flex;

  &.IconLeft {
    transform: translate(-5px, 0);
  }

  &.IconRight {
    transform: translate(5px, 0);
  }

  svg {
    * {
      stroke: currentColor;
    }
  }

  *.Invisible,
  *.Outline {
    svg * {
      stroke: $grey;
    }
  }
}
