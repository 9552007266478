@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Heebo:400,500,700);

.spinner {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
