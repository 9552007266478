@import '../../styles/variables';

.Container {
  margin: 0 $spacing-small $spacing-small;
  background-color: rgba($dark-grey, 0.1);
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  &--default {
    background-color: rgba($dark-grey, 0.1);
    .Header {
      background-color: rgba($dark-grey, 0.1);
    }
  }

  &--info {
    background-color: rgba($light-blue, 0.1);
    .Header {
      background-color: rgba($light-blue, 0.1);
    }
  }

  &--success {
    background-color: rgba($green, 0.1);
    .Header {
      background-color: rgba($green, 0.1);
    }
  }

  &--error {
    background-color: rgba($red, 0.1);
    .Header {
      background-color: rgba($red, 0.1);
    }
  }

  .Header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 $spacing-small;
    background-color: rgba($dark-grey, 0.1);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .Content {
    &--padding {
      padding: $spacing-small;
    }
  }
}
