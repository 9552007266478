.Layer {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: move;
  pointer-events: none;
}
