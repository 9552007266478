@import '../../styles/variables';

.ToggleHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}

.ToggleHeader__Label {
  display: flex;
  align-items: center;
  padding-right: $spacing-small / 2;
}

.ToggleContent {
  padding: 8px;
  border-top: 1px solid $medium-grey;
}
