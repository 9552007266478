@import '../../styles/variables';

.RangePickerDropdown {
  :global(.ant-calendar-date-input-wrap) {
    margin-left: 0;
  }

  :global(.ant-calendar-range-middle) {
    padding: 0;
  }
}
