@import '../../styles/variables';

.Option {
  position: relative;
  display: inline-flex;
  width: auto;
  height: 30px;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacing-small / 2 0 $spacing-small;
  border: 1px solid $medium-grey;
  background-color: $white;
  border-radius: $border-radius;
  cursor: pointer;
  font-size: 13px;
  font-weight: normal;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  .Option__Label,
  .Option__Description {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-align: inherit;
  }

  .OptionTick {
    position: relative;
    width: 24px;
    height: 24px;
    margin-left: $spacing-small / 2;
    color: $medium-grey;
    transition: color 0.3s ease-in-out;

    svg {
      path {
        display: none;
      }
    }
  }

  .NoTick {
    margin-right: $spacing-small / 2;
  }

  &--selected {
    border: 1px solid $blue;

    .OptionTick {
      color: $blue;

      svg {
        path {
          display: block;
        }
      }
    }
  }

  &--noTick {
    justify-content: center;
  }
}
