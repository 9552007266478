@import '../../styles/variables';

.Container {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 50px;
  border-radius: $border-radius;

  &--border {
    border: 1px solid $medium-grey;
  }

  &--padding {
    padding: $spacing-small;
  }
}
