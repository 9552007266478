@import '../../styles/variables';

.Container {
  display: inline-flex;
  width: auto;
  height: 24px;
  align-items: center;
  padding: 0 $spacing-small / 2;
  background-color: $off-white;
  border-radius: $border-radius;

  .Text {
    color: rgba($light-blue, 0.7);
  }

  &--watermark {
    position: fixed;
    z-index: 999;
    right: 20px;
    bottom: 10px;
  }

  &--centered {
    margin: 20px auto 10px;
  }

  &--inverse {
    background-color: $dark-blue;

    .Text {
      color: $white;
    }
  }

  &--inverse-light {
    background-color: rgba(125, 163, 201, 0.15);

    .Text {
      color: rgba(125, 163, 201, 0.8);
    }
  }
}
