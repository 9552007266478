@import '../../styles/variables';

.Spinner {
  position: relative;
  width: 38px;
  height: 38px;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    stroke: $blue;
  }

  &--inverse {
    svg {
      stroke: $white;
    }
  }

  &--smaller {
    composes: Spinner;

    width: 24px;
    height: 24px;

    svg {
      transform: scale(0.7) translate(-6px, 1px);
    }
  }

  &--small {
    composes: Spinner;
  }

  &--medium {
    composes: Spinner;
    width: 57px;
    height: 57px;

    svg {
      transform: scale(1.5) translate(7px, 7px);
    }
  }

  &--large {
    composes: Spinner;
    width: 76px;
    height: 76px;

    svg {
      transform: scale(2) translate(10px, 10px);
    }
  }
}

.FullViewContainer {
  position: absolute;
  z-index: 900;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba($white, 0.9);
}

:global(.ant-spin-dot) {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
