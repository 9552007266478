@import '../../styles/variables';

.URLField__Container {
  padding-bottom: 30px;

  label {
    color: $dark-grey;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.URLField__Inner {
  display: flex;
  align-items: center;
}

.URLField__Domain {
  padding-right: 5px;
  color: $dark-grey;
  font-family: $font-family;
  font-size: 12px;
  font-weight: 500;
}

.URLField__TextField {
  width: 100px;
}
