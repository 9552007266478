@import '../../styles/variables';

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Icon {
  margin-bottom: 10px;

  svg {
    * { stroke: $red; }
  }
}

.Title {
  font-weight: $heading-font-weight;
}

.ButtonGroup {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-top: 24px;
}

.Button {
  text-align: center;
}
