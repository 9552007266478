@import '../../styles/variables';
@import '../../styles/mixins';

.TriggerContainer {
  display: inline-flex;
  width: 100%;
  align-items: center;
  color: inherit;
  cursor: pointer;
}

.Disabled {
  @include disabled;
}
