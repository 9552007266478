@import '../../styles/variables';

.LegendItem {
  display: flex;
  align-items: center;

  &__dot {
    background: $green;
    border-radius: 50%;
  }
}
