// Colors
$slate: #22344d;
$dark-slate: #223149;

$background: #fefefe;
$dark-background: #1b2a3f;

$white: #fff;
$dark-off-white: #e2e2e2;
$light-off-white: #f9f9f9;
$off-white: #f5f7fa;
$light-blue-grey: #f8fafc;
$light-grey: #f0f0f0;
$lighter-grey: #f6f6f6;
$medium-grey: #dedede; // Borders
$dark-grey: #647283;
$dark-blue: #1b2a3f; // Text
$grey-fade: rgba(136, 146, 168, 0.3); // Inactive Items

$blue: #1e7bde;
$dim-blue: #3f8ee2; // Button Hover ONLY
$cyan: #1eb3de;
$light-blue: #7299c2;
$blue-grey: #8892a8;
$dark-blue-grey: #6d7993;
$light-greyish-blue: #e2e7ef;
$dark-greyish-blue: #2a2e36;
$blue-icon-background: #d3e5f7;
$light-slate: #5eacff;
$dark-charcoal: #30343e;

$red: #da4e59;
$dim-red: #df6871; // Button Hover ONLY
$light-red: rgb(248, 220, 222);

$green: #80a845;
$dim-green: #92b460; // Button Hover ONLY
$light-green: #bfe2a0;
$green-icon-background: #e6eedb;

$orange: #e7a036;
$dim-orange: #eaae53;
$orange-10alpha: rgba(226, 143, 19, 0.1);

$purple: #8593f3;
$dim-purple: #87a8cb;

// Status
$status-unsubmitted: #6ca5f4;
$status-submitted-review: #2b5a9e;
$status-needs-changes: #e4a735;
$status-rejected: #e36e7a;
$status-approved: #98b329;
$status-closed: #6a6e74;

// Form Fields
$field-text: #e1c1a3;
$field-multi_line_text: #c7ccd4;
$field-numeric: #d5a3e1;
$field-checkbox: #a6a3e1;
$field-radio: #e1a4a3;
$field-signature: #9adcc5;
$field-date: #98e0ef;
$field-boolean: #92b3c2;
$field-phone: #a6d183;
$field-email: #ffd379;
$field-currency: #b7d6fa;

$grey: #c4c4c4; // Buttons ONLY
$dim-grey: #bababa; // Button Hover ONLY

$table-hover: #fdfdfd;

// Comments
$comment-applicant: #47699a;
$comment-agency: #e4e9f0;

$font-family: 'Roboto', sans-serif;

$paragraph-size: 16px;
$paragraph-font-weight: 400;
$paragraph-font-style: normal;
$paragraph-font-family: $font-family;
$paragraph-line-height: 26px;

$heading-size: 32px;
$heading-font-weight: 500;
$heading-font-style: normal;
$heading-font-family: 'Fira Sans', sans-serif;

$subheading-font-family: 'Heebo', sans-serif;

$sub-heading-size: 20px;
$logo-heading-size: 16px;

$button-font-weight: 500;
$button-font-style: normal;
$button-font-family: $font-family;

// Component variables
$applicant-page-width: 1080px;
$applicant-page-height: 100px;
$applicant-page-spacing: 26px;
$applicant-header-font-weight: 500;
$guide-step-sidebar-width: 430px;
$admin-sidemenu-width: 205px;
$dashboard-navbar-height: 54px;

// Styleguide spacing
$item-height: 50px;
$font-size: 14px;
$spacing-large: 18px;
$spacing-small: 12px;
$spacing-xsmall: 6px;
$spacing-tiny: 4px;
$step-actions-offset: 0;
$border-radius: 4px;

$animation-delay: 200ms;
$slideout-easing: cubic-bezier(0.23, 1, 0.32, 1);

// tree grid
$tree-offset: 30px;
$cell-offset: 13px;

// overlay
$overlay: rgba(42, 46, 54, 0.8);

// rings (outline (focus))
$blue-ring: rgba(30, 123, 222, 0.2);
$red-ring: rgba($red, 0.2);
$orange-ring: rgba($orange, 0.2);

// highlights
$blue-highlight: rgba(#7da3c9, 0.1);
