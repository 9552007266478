@import '../../styles/variables';

.Icon {
  display: inline-block;
  svg {
    * {
      fill: none;
      stroke: currentColor;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
    }
  }

  &.xlarge {
    width: 58px;
    height: 58px;

    svg {
      width: 58px;
      height: 58px;
    }
  }

  &.large {
    width: 48px;
    height: 48px;

    svg {
      width: 48px;
      height: 48px;
    }
  }

  &.medium {
    width: 36px;
    height: 36px;

    svg {
      width: 36px;
      height: 36px;
    }
  }

  &.small {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.smaller {
    width: 18px;
    height: 18px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}
