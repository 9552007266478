@import '../../styles/variables';

.Modal {
  overflow: auto;
}

.ModalContent {
  width: 480px;
  padding: $spacing-large;
}

.Icon {
  display: flex;
  justify-content: center;
  padding: 0 $spacing-large;
}

.Header {
  margin-bottom: $spacing-large;
  text-align: center;
}

.Text {
  margin: $spacing-large;
  text-align: center;
}

.Actions {
  margin-top: $spacing-large;
}
