@import '../../styles/mixins';
@import '../../styles/variables';

.Icon {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 8px;
  color: $blue-grey;
}

.TextField {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 $spacing-large;

  .InputContainer {
    position: relative;
    width: 100%;

    :global(.ant-select) {
      width: 100%;
    }
  }

  .Icon + input {
    padding-left: 35px;
  }

  :global(.ant-input.ant-select-search__field) {
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    padding: 9px 30px 8px $spacing-small;
    border-radius: $border-radius;
    color: $dark-blue;
    font-family: $paragraph-font-family;
    font-size: 14px;
    font-weight: $paragraph-font-weight;
    outline: none;
    transition: 0.2s ease-in-out all;

    &:focus {
      border: 1px solid $blue;
    }

    &::-webkit-input-placeholder {
      color: $dark-grey;
    }

    &::-moz-placeholder {
      color: $dark-grey;
    }

    &:-ms-input-placeholder {
      color: $dark-grey;
    }

    &:-moz-placeholder {
      color: $dark-grey;
    }

    &.hasError {
      border-color: $red;
    }

    &.Disabled {
      @include disabled;
    }
  }

  :global(.ant-select-auto-complete.ant-select .ant-select-selection__rendered) {
    line-height: 40px;
  }

  :global(.ant-select-selection__placeholder) {
    color: $blue-grey;
  }

  label {
    display: block;
    margin-bottom: $spacing-small / 3;
    color: $blue-grey;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    white-space: normal;
  }

  .Description {
    display: block;
    color: $dark-grey;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    text-transform: none;
  }

  .LabelTooltip {
    position: relative;
    svg {
      color: $blue-grey;
    }
  }
}

.HeadingWrapper {
  display: flex;
  justify-content: space-between;
  line-height: 18px;
}

.HeadingOptional {
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
  text-transform: initial;
}

:global(.ant-select-dropdown-menu-item) {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  &:hover {
    background-color: transparent;
  }
}
