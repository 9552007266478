@import '../../styles/variables';

$size: 100px;

.Base {
  position: relative;
  overflow: scroll;
  min-width: 400px;
  min-height: 50px;
  max-height: 100vh;
  border: 1px solid $white;
  background: $white;
  border-radius: 2px;
  outline: none;
}

.Overlay {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#22263a, 0.8);
  opacity: 1;
  transition: 0.3s ease-in-out opacity;

  &--after {
    opacity: 1;
  }

  &--before {
    opacity: 0;
  }
}

.Header {
  position: absolute;
  top: 5px;
  right: 0;

  button {
    border: 0;
    background: none;
    cursor: pointer;
    outline: none;

    svg {
      * {
        stroke: $blue-grey;
        transition: 0.25s ease-in-out stroke;
      }
    }

    &:hover {
      svg {
        * {
          stroke: $red;
        }
      }
    }
  }
}

.Content {
  padding: 50px 50px 50px;
}
