@import '../../styles/variables';

.Container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  line-height: 24px;

  &--noSpace {
    justify-content: flex-start;
  }
}

.Heading {
  font-size: 13px;
}

.Description {
  padding-top: 8px;
  margin: 0 0 3px;
  color: $dark-grey;
  font-size: 12px;
  line-height: 20px;
}

.HeadingWrapper {
  padding-right: $spacing-large;
}

.SwitchWrapper {
  font-family: $button-font-family;
  font-size: 12px;
  font-weight: 700;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

:global {
  .ant-switch {
    height: 24px;
    background: $dark-off-white;

    &:focus {
      box-shadow: 0 0 0 3px rgba(30, 123, 222, 0.2), 0 0 0 1px $blue;
    }

    &::after {
      top: 2px;
      left: 2px;
      background-color: #cbcbcb;
      box-shadow: none;
      font-weight: 600;
    }

    &.ant-switch-checked {
      &::after {
        left: 100%;
        margin-left: -2px;
        background-color: $blue;
        transform: translateX(-100%);
      }

      .ant-switch-inner {
        padding-left: 0;
        color: $blue;
      }
    }

    .ant-switch-inner {
      color: $dark-grey;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
