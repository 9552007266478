@import '../../styles/variables';

.Wrapper {
  background-color: $off-white;
}

.Modal {
  width: 400px;
  margin: -35px;
  text-align: center;

  h3 {
    max-width: 365px;
    padding-top: $spacing-small;
    margin: 0 auto;
    color: $red;
    font-family: $heading-font-family;
    font-size: 16px;

    span {
      text-decoration: underline;
    }
  }

  h5 {
    padding: 12px 0 0;
    margin: 0;
    color: $dark-grey;
    font-size: 14px;
  }

  p {
    padding: 12px 12px 23px;
    margin: 0;
    font-size: 14px;
    line-height: 24px;

    strong {
      font-weight: 500;
    }
  }
}

.Modal--blue {
  composes: Modal;

  h3 {
    color: $blue;
  }
}

.Modal--green {
  composes: Modal;

  h3 {
    color: $green;
  }
}

.ModalActions {
  padding-top: 30px;
  text-align: right;

  button:first-child {
    margin-right: 20px;
  }
}

.ModalIcon {
  padding: $spacing-small $spacing-small 0 $spacing-small;

  .ModalIcon__Inner {
    display: inline-block;
    padding: 8px;
    background: $light-red;
    border-radius: 24px;
    color: $red;
    line-height: 11px;

    svg {
      * {
        stroke: $red;
      }
    }
  }
}

.ModalIcon--blue {
  composes: ModalIcon;

  .ModalIcon__Inner {
    background: $blue-icon-background;
    color: $blue;

    svg {
      * {
        stroke: $blue;
      }
    }
  }
}

.ModalIcon--green {
  composes: ModalIcon;

  .ModalIcon__Inner {
    background: $green-icon-background;
    color: $green;

    svg {
      * {
        stroke: $green;
      }
    }
  }
}
