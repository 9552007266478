@import '../../styles/variables';

.Container {
  display: inline-flex;
  align-items: center;

  .Icon {
    position: relative;
    left: -3px;

    svg {
      path {
        fill: $blue-grey;
        stroke: $blue-grey;
      }

      ellipse {
        fill: $white;
        stroke: $white;
      }
    }
  }
}
