@import '../../styles/variables';

.Icon {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 8px;
  color: $blue-grey;
}

.TextField {
  &--Disabled {
    opacity: 0.5;
  }

  .Icon + input {
    padding-left: 35px;
  }

  .Input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 9px $spacing-small 8px;
    background-color: $white;
    color: $dark-blue;
    font-family: $paragraph-font-family;
    font-size: 14px;
    font-weight: $paragraph-font-weight;
    outline: none;
    transition: 0.2s ease-in-out all;

    &:focus {
      border: 1px solid $blue;
    }

    &::-webkit-input-placeholder {
      color: $blue-grey;
    }

    &::-moz-placeholder {
      color: $blue-grey;
    }

    &:-ms-input-placeholder {
      color: $blue-grey;
    }

    &:-moz-placeholder {
      color: $blue-grey;
    }
  }
}

.CopyIcon {
  cursor: pointer;

  svg {
    * {
      stroke: $blue-grey;
    }
  }

  &:hover {
    svg {
      * {
        stroke: $blue;
      }
    }
  }
}

.CopyText {
  height: 30px;
  cursor: pointer;

  &:hover {
    color: $blue;
  }
}
