@import '../../styles/variables';

.Container {
  display: flex;
  height: 150px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .Image {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }

  a {
    color: $blue;
  }
}
