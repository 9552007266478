@import '../../styles/variables';

.Divider {
  width: 100%;
  border: none;
  border-top: 0.0625rem solid $medium-grey;
  margin: 0.75rem 0;

  &--inverse {
    border-color: $dark-blue;
  }
}
