@import '../../styles/variables';

.Badge {
  display: inline-block;
  padding: 7px 16px;
  border: 2px solid;
  border-radius: 3px;
  font-family: $font-family;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  &.Fill {
    color: white;
  }

  &--green {
    border-color: $green;
    color: $green;

    &.Fill {
      background-color: $green;
    }
  }

  &--orange {
    border-color: $orange;
    color: $orange;

    &.Fill {
      background-color: $orange;
    }
  }

  &--red {
    border-color: $red;
    color: $red;

    &.Fill {
      background-color: $red;
    }
  }

  &--grey {
    border-color: $dark-grey;
    color: $dark-grey;

    &.Fill {
      background-color: $dark-grey;
    }
  }

  &--blue {
    border-color: $blue;
    color: $blue;

    &.Fill {
      background-color: $blue;
    }
  }

  &--light-blue {
    border-color: $light-blue;
    color: $light-blue;

    &.Fill {
      background-color: $light-blue;
    }
  }

  &--cyan {
    border-color: $cyan;
    color: $cyan;

    &.Fill {
      background-color: $cyan;
    }
  }

  &--purple {
    border-color: $purple;
    color: $purple;

    &.Fill {
      background-color: $purple;
    }
  }
}
