@import '../../styles/variables';

.Dropzone {
  background: $white;
  font-size: 13px;
}

.Content {
  display: flex;
  margin: 0;
}

.Preview {
  display: flex;
  overflow: hidden;
  width: 100px;
  border: 1px solid $medium-grey;
  border-radius: 4px;
  cursor: pointer;
  font-size: 25px;
  line-height: 100px;
  text-align: center;
  text-transform: uppercase;
}

.Overlay {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  opacity: 0;
  transition: 0.3s linear opacity;

  div {
    margin: 0 auto;

    svg {
      * {
        stroke: $white;
      }

      transform: translateY(-34px);
    }
  }
}

.Image {
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  flex: 1;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(34, 38, 58, 0);
    content: '';
    transition: 0.3s linear background-color;
  }

  &:hover {
    .Overlay {
      background: rgba(34, 38, 58, 0.8);
      opacity: 1;
    }

    &::before {
      opacity: 1;
    }
  }

  img {
    max-width: 100px;
    max-height: 100px;
  }
}

.Placeholder {
  width: 100px;
  height: 100px;
  background: #eee;
}

.Details {
  flex: 1;
  text-align: right;
}

.FileName {
  width: 200px;
  padding: 0 13px 13px 0;
  float: right;
}

.ErrorMessage {
  color: $red;
  font-size: 12px;
  font-weight: bold;
}

.WarnMessage {
  color: $orange;
  font-size: 12px;
  font-weight: bold;
}

.Button {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  margin-top: 5px;
}

.HeadingWrapper {
  display: flex;
  justify-content: space-between;

  .Label {
    display: flex;
    min-height: 24px;
    align-items: center;

    .Required {
      margin-left: 4px;
      color: $red;
    }
  }
}

.HeadingOptional {
  position: relative;
  top: 3px;
  color: $blue-grey;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  text-transform: initial;
}
