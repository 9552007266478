@import '../../styles/variables';

.Card {
  display: flex;
  padding: 15px;
  background-color: $white;
  border-radius: 3px;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);

  & + & {
    margin-top: 20px;
  }
}

.Index {
  height: 24px;
  flex: 0 0 24px;
  margin-right: 15px;
  background-color: #1e7bde;
  border-radius: 21px;
  color: $white;
  font-size: 13px;
  font-weight: bold;
  line-height: 23px;
  text-align: center;
}

.Content {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}

.ContentHeading {
  flex: 1 1 auto;
  padding-bottom: 20px;
}
