@import '../../styles/variables';

.ErrorWrapper {
  width: 50%;
  min-width: 550px;
  margin: 100px auto 0;
}

.ErrorCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: white;
  border-radius: 10px;
}

.TextContent {
  padding: $spacing-small 0;

  p {
    margin: 0;
    text-align: center;
  }
}

.EmailMessage {
  padding-top: $spacing-small;
  color: $dark-grey;
  font-weight: 500;
  text-align: center;

  a {
    color: $blue;
  }
}
