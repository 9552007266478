@import '../../styles/variables';

.Wrapper {
  display: flex;
}

.Pages {
  flex: 1;
  margin: 0;
}

.PagesText {
  position: relative;
  height: 30px;
  box-sizing: content-box;
  padding: 6px 12px 0;
  border: 1px solid $medium-grey;
  background-color: $white;
  color: $dark-grey;
  float: left;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
}

.Pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0;
  border-radius: $border-radius;
}

.PaginationItem {
  display: inline;
  cursor: pointer;
}

.PaginationItem--Disabled {
  composes: PaginationItem;
  border-color: $medium-grey;
  background-color: $white;
  color: $dark-grey;
  cursor: not-allowed;
}

.Link {
  position: relative;
  width: 37px;
  height: 36px;
  padding: 6px 6px 0;
  border: 1px solid $medium-grey;
  margin-left: -1px;
  background-color: $white;
  color: $dark-grey;
  cursor: pointer;
  float: left;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-align: center;
  text-decoration: none;

  &:focus {
    outline: 0;
  }
}

.Link--Active {
  composes: Link;
  z-index: 2;
  border: 2px solid $blue;
  color: $blue;
  cursor: default;
}

.Icon {
  svg {
    * {
      stroke: $dark-grey;
    }
  }
}
