@import '../../styles/variables';

.Container {
  position: relative;

  input[type='password'],
  input[type='text'] {
    padding-right: 40px;
  }
}

.Input {
  padding-right: 52px;
}

.Button {
  position: absolute;
  right: 0;
  bottom: 1px;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;

  &,
  &:focus,
  &:after,
  &:hover {
    background-color: transparent;
  }

  svg * {
    fill: currentColor;
    stroke: none;
  }
}
