@import '../../styles/variables';

.Missing {
  display: flex;
  height: 100%;
  align-items: center;

  .Inner {
    flex: 1;
    color: $blue;
    text-align: center;

    h1 {
      font-size: 100px;
    }

    a {
      color: $blue;
      text-decoration: underline;
    }
  }

  .Status {
    margin: 0 0 20px;
    color: $blue;
    font-family: $heading-font-family;
    font-size: 120px;
    font-weight: bold;
    line-height: 120px;
  }

  .Heading {
    margin-bottom: 20px;
    color: $dark-greyish-blue;
    font-family: $heading-font-family;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
  }

  .Description {
    margin-bottom: 20px;
    color: $dark-greyish-blue;
    font-size: 16px;
    line-height: 24px;
  }

  .Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue-grey;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    .Icon {
      margin-right: $spacing-small / 2;
    }

    .Link {
      margin-left: 5px;
      color: $blue;
      font-size: inherit;
    }
  }
}
