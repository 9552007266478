@import '../../styles/variables';

.Card {
  :global(.ant-card-head-title) {
    font-family: $heading-font-family;
    font-size: 15px;
    font-weight: $heading-font-weight;
  }

  &:global(.ant-card-bordered) {
    border: none;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  }
}
