@import '../../styles/variables';

.TableContainer {
  min-width: 600px;
}

.Footer {
  display: flex;
  height: 50px;
  flex-direction: row;
  align-items: center;
}

div.ActionsGroup {
  top: 0;
  right: 0;
  height: 50px;
  justify-content: flex-end;
  border-left: 1px solid $medium-grey;
  line-height: 50px;
}
// specificity
span.Actions {
  box-sizing: border-box;
  padding: 0;

  &:first-child {
    padding: 0 0 0 10px;
  }

  &:last-child {
    padding: 0 10px 0 0;
  }
}

.Row {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  border: 1px solid $medium-grey;
  border-width: 1px 1px 0;
  background: $white;
  color: $dark-blue;
  cursor: default;
  font-size: 14px;
  font-weight: 400;
  outline: none;

  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  &:last-child {
    border-width: 1px;
    border-radius: 0 0 3px 3px;
  }

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: $dark-blue;
    text-decoration: none;
  }

  &--Alternative {
    composes: Row;
    background: $light-off-white;

    &:hover {
      background: $light-off-white;
    }
  }
}

.Clickable {
  cursor: pointer;
}

.Grid {
  outline: none;
}

.HeaderRow {
  display: flex;
  height: auto;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
}

.Header {
  padding: 13px;
  background: none;
  color: $dark-grey;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.Column {
  padding: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.NoData {
  padding: 25px;
  color: $dark-grey;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
