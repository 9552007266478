@import '../../styles/variables';

.IconButton {
  display: inline-flex;
  min-width: 45px;
  height: auto;
  box-sizing: border-box;
  align-items: center;
  padding: 5px;
  border: none;
  background: transparent;
  color: $dark-grey;
  cursor: pointer;
  font-family: $font-family;
  font-size: 14px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
  vertical-align: middle;

  svg {
    * {
      stroke: $dark-grey;
    }
  }

  .Icon {
    display: inline-flex;
    align-items: center;
  }

  &.IconButton__Text {
    position: relative;
    top: 2px;
    padding-left: 5px;
  }

  &:hover {
    color: $blue;

    svg {
      * {
        stroke: $blue;
      }
    }
  }

  &--bordered {
    border: 1px solid $medium-grey;
    border-radius: $border-radius;

    &:hover {
      border-color: inherit;
    }
  }

  &--disabled {
    color: $dark-grey;
    cursor: not-allowed;
    opacity: 0.7;

    svg {
      * {
        stroke: $dark-grey;
      }
    }

    &:hover {
      color: $dark-grey;

      svg {
        * {
          stroke: $dark-grey;
        }
      }
    }
  }

  &--delete {
    &:hover {
      color: $red;

      svg {
        * {
          stroke: $red;
        }
      }
    }
  }
}
