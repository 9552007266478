@import '../../styles/variables';

.Wrapper {
  position: fixed;
  z-index: 999999999;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
}

$notification-size: 50px;

.Notification {
  position: relative;
  z-index: 999999999;
  display: inline-flex;
  min-width: 150px;
  max-width: 700px;
  height: $notification-size;
  box-sizing: border-box;
  align-items: center;
  border-radius: 100vw;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);

  .NotificationText {
    display: flex;
    width: calc(100% - #{$notification-size * 2});
    flex-grow: 1;
    padding-right: 13px;
  }

  .NotificationIcon {
    position: relative;
    display: flex;
    width: $notification-size;
    align-items: center;
    justify-content: center;

    .NotificationIcon__Icon {
      color: $white;
    }
  }

  .DismissButton {
    right: 0;
    display: flex;
    width: $notification-size;
    height: 100%;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: rgba($dark-greyish-blue, 0.05);
    border-radius: 40px;
    color: $white;
    cursor: pointer;
    outline: 0;

    svg {
      * {
        transition: 0.1s linear;
      }
    }

    &:hover {
      svg {
        * {
          stroke: rgba($white, 0.75);
          transition: 0.1s linear;
        }
      }
    }
  }

  &--failure {
    composes: Notification;
    background: $red;
  }

  &--success {
    composes: Notification;
    background: $green;
  }

  &--information {
    composes: Notification;
    background: $blue;
  }
}

.Transition {
  &--appear {
    opacity: 0.01;
    transform: translateY(-18px) translateX(-50%);
  }

  &--appearActive {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
    transition: opacity, transform 0.3s ease-in-out;
  }
}
