@import '../../styles/variables';

.MultiOptionPickerField {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 $spacing-large;

  &--Disabled {
    opacity: 0.5;
  }

  .OptionList {
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin: -$spacing-small 0 0 0;

    .OptionList__Item {
      position: relative;
      display: inline-flex;
      align-items: center;
      margin: $spacing-small $spacing-small 0 0;

      &--evenly {
        flex: 1;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .Label {
    display: block;
    margin-bottom: $spacing-small / 3;
    color: $blue-grey;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    white-space: normal;

    .Label__Wrapper {
      display: flex;
      justify-content: space-between;
      line-height: 18px;
    }

    .Label__Tooltip {
      position: relative;
      top: 4px;
    }
  }

  &--ColumnDirection {
    .OptionList {
      flex-direction: column;
      align-items: flex-start;

      .OptionList__Item {
        width: 100%;
      }
    }
  }
}
