@import '../../styles/variables';

.AttachmentUpload {
  position: relative;
}

.AttachmentSpinner {
  position: absolute;
  z-index: 9;
  top: -20px;
  right: -20px;
  bottom: -20px;
  left: -20px;
  display: flex;
  align-items: center;
  background: rgba($white, 0.95);

  div {
    transform: translateX(100px);
  }
}
