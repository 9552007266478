@import '../../styles/variables';

@mixin rtf-paragraph {
  color: $dark-blue;
  font-family: $font-family;
  font-size: 13;
}

@mixin rtf-h1 {
  display: block;
  padding: 2px 0;
  margin: 0;
  color: $dark-blue;
  font-family: $font-family;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

@mixin rtf-h2 {
  display: block;
  padding: 2px 0;
  margin: 0;
  color: $dark-blue;
  font-family: $font-family;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

@mixin rtf-h3 {
  display: block;
  padding: 2px 0;
  margin: 0;
  color: $dark-blue;
  font-family: $font-family;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}

@mixin rtf-h4 {
  display: block;
  padding: 2px 0;
  margin: 0;
  color: $dark-grey;
  font-family: $font-family;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}
