@import '../../styles/variables';

.MenuItem {
  &__Enabled {
    display: inline-flex;
    width: 100%;
    height: 30px;
    align-items: center;
    background-color: $white;
    color: $dark-blue;
    cursor: pointer;

    .Element {
      display: inline-block;
      flex-grow: 1;
      align-items: center;
      padding: $spacing-small;

      .Icon {
        display: inline-block;
      }
    }
    &:hover {
      background-color: rgba(245, 247, 250, 0.5);
    }

    .OptionLabel {
      position: absolute;
      left: 38px;
      color: $dark-greyish-blue;
      font-size: 13px;
      font-weight: 400;
    }

    .CheckedText {
      font-weight: 500;
    }
  }

  &__Disabled {
    display: inline-flex;
    width: 100%;
    height: 30px;
    align-items: center;
    background-color: $white;
    color: $dark-blue;
    cursor: pointer;

    .Element {
      display: inline-block;
      flex-grow: 1;
      align-items: center;
      padding: $spacing-small;

      .Icon {
        display: inline-block;
      }
    }

    .DisabledElement {
      cursor: not-allowed;
    }

    .CheckedIcon {
      svg {
        * {
          fill: $blue-grey;
          stroke: $blue-grey;
        }
      }
    }

    .OptionLabel {
      position: absolute;
      left: 38px;
      color: $blue-grey;
      font-size: 13px;
    }

    .CheckedText {
      font-weight: 500;
    }
  }
}

.Menu {
  display: flex;
  overflow: hidden;
  flex: auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-left: 1px solid $light-off-white;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.MenuList {
  position: absolute;
  z-index: 1005;
  right: 0;
  min-width: 225px;
  box-sizing: border-box;
  border: 1px solid rgba(#1d1d28, 0.07);
  background: $white;
  box-shadow: 0 3px 30px rgba($dark-blue, 0.07);

  .MenuGroup {
    display: flex;
    flex-direction: column;
    margin: $spacing-small 0;

    .MenuGroup__Label {
      padding: 0 0 0 $spacing-small;
    }
  }

  .DropdownMenu__List {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
  }
}
