@import '../../styles/variables';

.PickerItem {
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  border: 1px solid transparent;
  margin: 0 5px 0 0;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.2s linear all;
  &:hover {
    opacity: 0.8;
  }
}

.Tick {
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.Colors {
  display: flex;
}

.Label {
  display: flex;
  min-height: 24px;
  align-items: center;

  .Required {
    margin-left: 4px;
    color: $red;
  }
}

.PickerItem--Disabled {
  &:hover {
    opacity: 1;
  }
}

.ColorPicker {
  margin: 0 0 18px;
}
