@import '../../styles/variables';

$hover-color: rgba($light-blue, 0.05);
$cell-padding: $spacing-small $spacing-large;
$cell-padding-thin: 7px $spacing-large;

.Table {
  :global(.ant-table) {
    border: 1px solid $medium-grey;
    border-radius: 3px;
  }

  :global(.ant-spin-nested-loading) {
    :global(.ant-spin) {
      max-height: 100% !important;
    }

    :global(.ant-table-placeholder) {
      border-bottom: none;
    }
  }

  :global(.ant-table-fixed) {
    table-layout: fixed;
  }

  :global(.ant-table-header),
  :global(.ant-table-thead > tr),
  :global(.ant-table-tbody > tr > td.ant-table-column-sort),
  :global(.ant-table-thead > tr > th.ant-table-column-sort),
  :global(.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td),
  :global(.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td) {
    background: $white;
  }

  :global(.ant-table-scroll table .ant-table-fixed-columns-in-body) {
    visibility: visible;
    & > * {
      visibility: hidden;
    }
  }

  :global(.ant-table-thead
      > tr
      > th
      .ant-table-header-column
      .ant-table-column-sorters:hover::before),
  :global(.ant-table-thead > tr > th.ant-table-column-has-actions:hover),
  :global(.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td),
  :global(.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td) {
    background-color: $hover-color;
  }

  :global(.ant-table-content) {
    th {
      position: relative;
      padding: 8px $spacing-large;
      border-bottom: 1px solid $medium-grey;
      background: $white;
      border-radius: 0;
      color: $dark-greyish-blue;
      font-family: $font-family;
      font-size: 12px;
      font-weight: 700;
    }

    :global(.ant-table-tbody) {
      tr {
        background-color: $white;
      }

      tr:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    :global(.ant-table-column-sorter) {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    :global(.ant-table-thead th.ant-table-column-sort) {
      border-bottom: 1px solid $blue;
      background-color: $white;
    }
  }

  td {
    padding: $spacing-small $spacing-large;
    border: none;
    color: $dark-greyish-blue;
    font-family: $font-family;
    font-size: 13px;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  tbody {
    background-color: $white;

    &:hover {
      background-color: $off-white;
    }

    tr:not(:first-child) {
      border-top: 1px solid $medium-grey;
    }
  }
}

:global(.ant-table-fixed-right) {
  background-color: $white;
}

.ResizableBox {
  position: relative;
  background-clip: padding-box;

  &:hover {
    &::after {
      position: absolute;
      top: 12px;
      right: 2px;
      color: rgba($blue, 0.5);
      content: ' \25C0';
      font-size: 8px;
    }

    &:not(:last-of-type) {
      box-shadow: inset -2px 0 0 rgba($blue, 0.5);

      & + th::before {
        position: absolute;
        top: 12px;
        left: 2px;
        color: rgba($blue, 0.5);
        content: ' \25b6';
        font-size: 8px;
      }
    }
  }
}

.Handle {
  position: absolute;
  z-index: 1;
  right: -12px;
  bottom: 0;
  width: 25px;
  height: 100%;
  cursor: col-resize;

  &:focus {
    outline: none;
  }
}

.ClickableRows {
  cursor: pointer;
}

.Paginator {
  padding-top: 10px;
}

.NormalRows {
  :global(.ant-table-tbody > tr > td) {
    padding: $cell-padding;
  }
}

.ThinRows {
  :global(.ant-table-tbody > tr > td) {
    padding: $cell-padding-thin;
  }
}
