@import '../../styles/variables';

.SearchField {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;

  &__icon {
    position: absolute;
    top: 0;
    display: flex;
    width: 40px;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: $blue-grey;
    text-align: center;
    transition: 0.2s ease-in-out all;

    &.Focused {
      color: $blue;
    }
  }
}

.Input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid $medium-grey;
  border-radius: 2px;
  color: $dark-grey;
  font-family: $font-family;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  transition: 0.2s ease-in-out all;

  &::placeholder {
    text-overflow: ellipsis;
  }
}

:global(.ant-input) {
  padding: 0 0 0 40px;
  border: none;
}

:global(.ant-input:focus) {
  border: 1px solid $blue;
  box-shadow: none;
}

:global(.ant-calendar-picker-input.ant-input:focus) {
  border: none !important;
}

.OutlineInput {
  &:hover {
    border: 1px solid $blue;
  }

  &:focus {
    border: 1px solid $blue;
  }
}

.Focused {
  border-color: $blue;
}
