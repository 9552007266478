@import '../../styles/variables';

.wrapper {
  max-width: 420px;
  margin-bottom: 20px;
  user-select: none;

  .header {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
  }

  .calendarWrapper {
    border: 1px solid #d0d0d0;

    :global(.ant-fullcalendar) {
      border-top-color: $medium-grey;
    }

    :global(.ant-fullcalendar-date) {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    /* Header days */
    :global(.ant-fullcalendar th) {
      height: 40px;
      text-align: center;

      :global(.ant-fullcalendar-column-header-inner) {
        color: $blue-grey;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    /* Selectable Days */
    :global(.ant-fullcalendar-value) {
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba($dim-purple, 0.4);
      background-color: $white;
      border-radius: $border-radius;
      box-shadow: none;
      color: $purple;
      transition: border 0.3s linear, background-color 0.3s linear;

      &:hover {
        border: 1px solid $purple;
        background-color: rgba(133, 147, 243, 0.1);
      }
    }

    /* Selected Day */
    :global(.ant-fullcalendar-selected-day .ant-fullcalendar-value) {
      border: 1px solid $purple !important;
      background-color: rgba(133, 147, 243, 0.1) !important;
      font-weight: 500;
    }

    /* Todays Date */
    :global(.ant-fullcalendar-today .ant-fullcalendar-value) {
      box-shadow: none;
    }

    /* Disabled Days */
    :global(.ant-fullcalendar-disabled-cell .ant-fullcalendar-value) {
      border: none;
      color: $dark-greyish-blue;
      opacity: 0.3;
    }

    :global(.ant-fullcalendar td) {
      height: 50px;
      text-align: center;
    }
  }
}
