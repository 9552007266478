@import '../../styles/variables';

.Header {
  color: #22263a;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Attachment {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #eee;
  margin-top: 8px;
}

.AttachmentType {
  width: 40px;
  height: 40px;
  margin-left: 5px;
  background: $grey;
  border-radius: 2px;
  color: $white;
  font-family: $font-family;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
}

.AttachmentPath {
  overflow: hidden;
  flex: 1;
  padding-left: 15px;
  color: $dark-blue;
  font-family: $font-family;
  font-size: 13px;
  font-weight: 500;
  line-height: 40px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.AttachmentActions {
  width: 100px;
  padding-right: 5px;
  text-align: right;
}
