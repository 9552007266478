@import '../../styles/variables';

.Popup {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  align-items: center;
  padding: 15px;

  &--active {
    display: flex;
  }
}

.PopupInner {
  position: relative;
  min-width: 300px;
  box-sizing: border-box;
  padding: $spacing-small;
  border: 1px solid $medium-grey;
  margin: 0 auto;
  background: #fff;
  border-radius: 2px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
}

.PopupDismiss {
  position: absolute;
  top: -3px;
  right: -15px;
}
