.ButtonGroup {
  position: relative;
  display: inline-flex;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: flex-start;

  &.vertical {
    flex-direction: column;
  }

  &.fullWidth {
    width: 100%;
  }

  &.alignRight {
    justify-content: flex-end;
  }

  &.alignCenter {
    justify-content: center;
  }

  &.alignEven {
    justify-content: space-around;
  }

  .ButtonGroup__Item {
    width: auto;
    height: auto;

    &.FullWidth {
      width: 100%;
    }

    &.noMargin {
      margin-right: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}
