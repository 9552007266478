@import '../../styles/variables';

.DaysBetweenField {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 $spacing-large;

  &--Disabled {
    opacity: 0.5;
  }

  .Label {
    display: block;
    margin-bottom: $spacing-small / 3;
    color: $blue-grey;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    white-space: normal;

    .Label__Wrapper {
      display: flex;
      justify-content: space-between;
      line-height: 18px;
    }

    .Label__Tooltip {
      position: relative;
      top: 4px;
    }
  }

  .DaysBetweenField__Selects {
    position: relative;
    display: flex;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    align-items: center;
    padding: 0 $spacing-small;
    background-color: $white;
    border-radius: $border-radius;

    .Select {
      width: 50px;
      height: 30px;
      box-sizing: border-box;
      border: 1px solid $medium-grey;
      margin: 0 10px;
      background-color: $white;
      border-radius: $border-radius;
    }
  }
}
