@import '../../styles/variables';

.BackLink {
  display: inline-flex;
  min-width: 200px;
  align-items: center;
  color: $blue-grey;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:hover {
    color: $blue;

    .Back__Icon {
      background-color: rgba($blue, 0.2);
      color: $blue;
      svg {
        color: $blue;
      }
    }

    .Back__Text {
      color: $blue;
    }
  }

  .Back__Text {
    margin-left: $spacing-small;
  }

  .Back__Icon {
    background-color: rgba($dark-grey, 0.2);
    border-radius: 100%;
    opacity: 1;

    svg {
      color: $dark-grey;
    }
  }
}
