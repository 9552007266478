@import '../../styles/variables';

.ColumnChooser {
  display: flex;
  flex-direction: column;

  .Group {
    display: flex;
    flex-direction: column;
    margin: 0 0 $spacing-small 0;

    .Group__Label {
      padding: 0;
      margin-bottom: $spacing-small / 2;
    }
  }

  .List {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.ColumnItem {
  position: relative;

  &__Enabled {
    display: inline-flex;
    width: 100%;
    height: 30px;
    align-items: center;
    background-color: $white;
    color: $dark-blue;
    cursor: pointer;

    .Element {
      display: inline-block;
      flex-grow: 1;
      align-items: center;
      padding: $spacing-small 0;

      .Icon {
        display: inline-block;
      }
    }

    &:hover {
      background-color: rgba(245, 247, 250, 0.5);
    }

    .CheckedText {
      font-weight: 500;
    }
  }

  &__Disabled {
    display: inline-flex;
    width: 100%;
    height: 30px;
    align-items: center;
    background-color: $white;
    color: $dark-blue;
    cursor: pointer;

    .Element {
      display: inline-block;
      flex-grow: 1;
      align-items: center;
      padding: $spacing-small 0;

      .Icon {
        display: inline-block;
      }
    }

    .DisabledElement {
      cursor: not-allowed;
    }

    .CheckedIcon {
      svg {
        * {
          fill: $blue-grey;
          stroke: $blue-grey;
        }
      }
    }

    .CheckedText {
      font-weight: 500;
    }
  }
}

.OptionLabel {
  margin-left: $spacing-small;
  color: $blue-grey;
  font-size: 13px;
}
