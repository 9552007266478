@import '../../styles/variables';

.Container {
  display: flex;
  width: 200px;
  flex-direction: column;
  padding: 10px;
  border: 1px solid $medium-grey;
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.Button {
  height: 30px;
  margin-top: 10px;
}

.FilterIcon {
  margin-right: 4px;
}

.Trigger {
  display: flex;
  flex-direction: row;
  align-items: center;

  color: $blue-grey;
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: 0;
  }

  &:hover,
  &.Active {
    color: $blue;
    svg {
      * {
        stroke: $blue;
      }
    }
  }
}

.TriggerIcon {
  position: relative;
  height: 24px;
}

.HasFilter {
  position: absolute;
  top: 0;
  right: 4px;
  width: 8px;
  height: 8px;
  background-color: $blue;
  border-radius: 50%;
}
