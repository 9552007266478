@import '../../styles/variables';

.PhoneField {
  &--Disabled {
    opacity: 0.5;
  }

  :global(.PhoneInputInput) {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 9px $spacing-small 8px;
    border: none;
    background-color: $white;
    border-radius: 4px;
    color: $dark-blue;
    font-family: $paragraph-font-family;
    font-size: 14px;
    font-weight: $paragraph-font-weight;
    outline: none;
    transition: 0.2s ease-in-out all;

    &::-webkit-input-placeholder {
      color: $blue-grey;
    }

    &::-moz-placeholder {
      color: $blue-grey;
    }

    &:-ms-input-placeholder {
      color: $blue-grey;
    }

    &:-moz-placeholder {
      color: $blue-grey;
    }
  }

  :global(.PhoneInputCountry) {
    padding: 0 0 0 $spacing-small;
  }
}
