@import '../../styles/variables';

.DatePicker {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;

  div {
    width: 100%;
  }

  input {
    padding: 9px $spacing-small 8px;

    &::placeholder {
      color: $blue-grey;
    }
  }
}
