@import '../../styles/variables';

.Column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &--padding {
    padding: $spacing-small;
  }

  .Column__Inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    &.Column__Inline {
      display: flex;
      flex-direction: row;
    }
  }

  .Column__Title {
    margin-bottom: 4px;
    text-transform: uppercase;
  }
}
