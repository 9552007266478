@import '../../styles/variables';

@mixin box {
  padding: 8px;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
}

.Dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 6px 50px;
  border: 2px dashed $medium-grey;

  cursor: pointer;
  user-select: none;

  &:hover {
    border-color: $blue;
  }
  .TitleText {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-small;

    .UploadIcon {
      margin-right: $spacing-tiny;
      svg {
        * {
          stroke: $blue-grey;
        }
      }
    }
  }
}

.Preview {
  .FileInfo {
    @include box;

    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-small;

    .Image {
      max-width: 70px;
      max-height: 100px;
    }

    .Details {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      margin-left: $spacing-small;
    }
  }

  .Resize {
    @include box;

    .Field {
      margin-bottom: 0;
    }
  }

  .AltText {
    @include box;
    margin-bottom: $spacing-small;

    .AltTextField {
      margin-bottom: 0;
    }
  }

  .Button {
    margin-top: $spacing-small;
  }
}
