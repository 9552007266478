@import '../../../styles/variables';

.ErrorContainer {
  display: flex;
  min-height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $spacing-small;
  border: 1px solid $medium-grey;
  background: white;
  border-radius: 2px;
}

.Button {
  margin-top: $spacing-small;
}
