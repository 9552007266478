@import '../../../styles/variables';

.Container {
  position: relative;
  width: 100%;
}

.PDFOverlay {
  width: 100%;
  padding: 2px;
  background-color: white;
  border-radius: 2px;
  visibility: hidden;

  &.Hide {
    display: none;
  }

  &.Loaded {
    visibility: visible;
  }

  &.SelectedField {
    opacity: 0.6;
  }

  &:focus {
    outline: 0;
  }
}

.Spinner {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 800px;
  align-items: center;
  justify-content: center;
}
