@mixin cursor-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

@mixin cursor-grabbing {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin prefix($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }

    // Output standard non-prefixed declaration
    #{$property}: $value;
  }
}

$grey-fade: rgba(136, 146, 168, 0.3); // Inactive Items
$dark-grey: #647283;

@mixin disabled {
  border-color: $grey-fade;
  color: $dark-grey;
  cursor: not-allowed;
  user-select: none;
}
