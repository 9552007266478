@import '../../styles/variables';

/* Normalizer */
.Base {
  display: block;
  margin: 0;
  color: inherit;
  font-family: $font-family;
  font-weight: normal;
}

.Text {
  composes: Base;

  &.Heading {
    font-family: $heading-font-family;
  }

  &.Italic {
    font-style: italic;
  }

  &.Uppercase {
    text-transform: uppercase;
  }

  &.FullWidth {
    width: 100%;
  }

  &.Inherit {
    color: inherit;
  }

  &.Green {
    color: $green;
  }

  &.Red {
    color: $red;
  }

  &.Orange {
    color: $orange;
  }

  &.Dark {
    color: $dark-greyish-blue;
  }

  &.DarkBlue {
    color: $dark-blue;
  }

  &.LightBlue {
    color: $light-blue;
  }

  &.Blue {
    color: $blue;
  }

  &.Grey {
    color: $blue-grey;
  }

  &.LightGrey {
    color: $light-grey;
  }

  &.DarkGrey {
    color: $dark-blue-grey;
  }

  &.DarkCharcoal {
    color: $dark-charcoal;
  }

  &.White {
    color: $white;
  }

  &.XLarge {
    font-size: 18px;
    font-weight: 500;
  }

  &.Larger {
    font-size: 14px;
    font-weight: 500;
  }

  &.Large {
    font-size: 13px;
    font-weight: 500;
  }

  &.Medium {
    font-size: 12px;
  }

  &.Small {
    font-size: 11px;
  }

  /* Oversized */
  &.xl8 {
    font-size: 5rem;
    line-height: 6rem;
  }

  &.xl7 {
    font-size: 4.5rem;
    line-height: 5.25rem;
  }

  &.xl6 {
    font-size: 3.75rem;
    line-height: 4.5rem;
  }

  &.xl5 {
    font-size: 3.125rem;
    line-height: 3.75rem;
  }

  /* Headings */
  &.xl4 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  &.xl3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  &.xl2 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  &.xl {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  /* Body */
  &.lg {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  &.md-lg {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  &.md {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  &.sm {
    font-size: 0.8125rem;
    line-height: 1.25rem;
  }

  &.xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &.xxs {
    font-size: 0.6875rem;
    line-height: 0.9375rem;
  }

  /* Text modifiers */
  &.thin {
    font-weight: 300;
  }

  &.normal {
    font-weight: 400;
  }

  &.medium {
    font-weight: 500;
  }

  &.medium-bold {
    font-weight: 600;
  }

  &.bold {
    font-weight: 700;
  }
}
