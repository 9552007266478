@import '../../styles/variables';

.Heading {
  font-family: $heading-font-family;

  &.DarkBlue {
    color: $dark-blue;
  }

  &.Blue {
    color: $blue;
  }

  &.Grey {
    color: $blue-grey;
  }

  &.DarkGrey {
    color: $dark-blue-grey;
  }

  &.LightBlue {
    color: $light-blue;
  }

  &.Red {
    color: $red;
  }

  &.Green {
    color: $green;
  }

  &.Orange {
    color: $orange;
  }
}

h1.Heading {
  margin-bottom: 0;
  font-family: $heading-font-family;
  font-size: 22px;
  font-weight: 500;
}

h2.Heading {
  margin-bottom: 0;
  font-family: $heading-font-family;
  font-size: 20px;
  font-weight: 500;
}

h3.Heading {
  margin-bottom: 0;
  font-family: $heading-font-family;
  font-size: 16px;
  font-weight: 500;
}

h4.Heading {
  margin-bottom: 0;
  font-family: $heading-font-family;
  font-size: 14px;
  font-weight: 500;
}

h5.Heading {
  margin-bottom: 0;
  font-family: $font-family;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}
