@import '../../styles/variables';

.PasswordRequirements {
  display: flex;
  color: $blue-grey;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}

.Requirement {
  display: flex;
  padding: 4px 0 0;
  color: $dark-greyish-blue;
  font-size: 13px;
  line-height: 20px;
}

.EmptyCheckmark {
  width: 14px;
  height: 14px;
  border: 1px solid $medium-grey;
  margin: 4px 8px 4px 0;
  border-radius: 50%;
}

:global(.ant-popover) {
  z-index: 2001;
}

.Warning {
  svg {
    * {
      fill: $red !important;
    }
  }

  transform: scale(1.1) translate(0, -3px);
}

.Checkmark {
  svg {
    * {
      fill: $green !important;
    }
  }

  transform: translate(-4px);
}
