@import '../../styles/variables';

$opacity: 0.75;

.SSOButton {
  display: inline-flex;
  overflow: hidden;
  width: 100%;
  height: 40px;
  margin-bottom: $spacing-large;
  background-color: rgba($light-blue, $opacity);
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: rgba($light-blue, 1);
  }

  &--NoMargin {
    margin-bottom: 0;
  }

  &:focus {
    outline: 0;
  }

  .SSOButton__ImageContainer {
    display: flex;
    width: 40px;
    height: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba($light-blue, $opacity);
    background-color: $white;
    border-radius: 6px 0 0 6px;
    transition: border-color 0.2s linear, background-color 0.2s linear;

    &:hover {
      border-color: rgba($light-blue, 1);
    }

    .SSOButton__Image {
      width: 28px;
      height: 28px;
    }
  }

  .SSOButton__Text {
    display: flex;
    width: calc(100% - 40px);
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    color: $white;
  }

  // Custom Buttons

  &--google-oauth2 {
    background-color: rgba(#4285f4, $opacity);

    &:hover {
      background-color: rgba(#4285f4, 1);
    }

    .SSOButton__ImageContainer {
      border: 1px solid rgba(#4285f4, $opacity);
      &:hover {
        border-color: rgba(#4285f4, 1);
      }
    }
  }

  &--windowslive {
    background-color: rgba(#2f2f2f, $opacity);

    &:hover {
      background-color: rgba(#2f2f2f, 1);
    }

    .SSOButton__ImageContainer {
      border: unset;
      background-color: rgba(#2f2f2f, $opacity);

      &:hover {
        border-color: unset;
        background-color: rgba(#2f2f2f, 1);
      }
    }
  }

  &--camino-azure-ad {
    background-color: rgba(#2f2f2f, $opacity);

    &:hover {
      background-color: rgba(#2f2f2f, 1);
    }

    .SSOButton__ImageContainer {
      border: 1px solid rgba(#2f2f2f, $opacity);

      &:hover {
        border-color: unset;
        background-color: rgba(#2f2f2f, 1);
      }
    }
  }

  &--beverly-hills-oidc {
    background-color: rgba(#456a6a, $opacity);

    &:hover {
      background-color: rgba(#456a6a, 1);
    }

    .SSOButton__ImageContainer {
      border: 1px solid rgba(#456a6a, $opacity);
      &:hover {
        border-color: rgba(#456a6a, 1);
      }
    }
  }
}
