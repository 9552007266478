@import '../../styles/variables';

.Icon {
  padding: 0 12px 18px;

  .Icon__Inner {
    padding: 4px;
    border-radius: 100%;
    line-height: 0;
  }

  &.Type--default {
    .Icon__Inner {
      background: rgba($dark-grey, 0.2);
      svg {
        color: $dark-grey;
      }
    }
  }

  &.Type--primary {
    .Icon__Inner {
      background: rgba($blue, 0.2);
      svg {
        color: $blue;
      }
    }
  }

  &.Type--success {
    .Icon__Inner {
      background: rgba($green, 0.2);
      svg {
        color: $green;
      }
    }
  }

  &.Type--error {
    .Icon__Inner {
      background: rgba($red, 0.2);
      svg {
        color: $red;
      }
    }
  }

  &.Type--inherit {
    .Icon__Inner {
      background: inherit;
      svg {
        color: inherit;
      }
    }
  }

  &.noPadding {
    padding: 0;
  }
}
