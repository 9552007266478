@import './headings';
@import '../../styles/variables';
@import '../../styles/mixins';

.ErrorMessage {
  color: $red;
  font-family: $font-family;
  font-size: 12px;
  font-weight: 500;
}

.Toolbar {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 0;
  background: $blue-highlight;

  &.Disabled {
    .ButtonGroupItem {
      @include disabled;

      svg {
        * {
          fill: $dark-blue-grey;
          stroke: $dark-blue-grey;
        }
      }

      &:hover {
        svg {
          * {
            fill: $dark-blue-grey;
            stroke: $dark-blue-grey;
          }
        }
      }
    }

    .StyleMenuTrigger {
      * {
        @include disabled;
      }
    }
  }
}

.Wrapper {
  min-width: 460px;
}

.RichTextField {
  position: relative;
  border: 1px solid $medium-grey;
  background-color: $white;
  border-radius: $border-radius;
  color: $dark-blue;
  font-family: $font-family;
  font-size: 12px;
  transition: 0.2s ease-in-out all;

  a[data-attachment='true'] {
    position: relative;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    text-align: center;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(34, 38, 58, 0.8);
      background-image: url('./assets/zoom.png');
      background-position: 50% 50%;
      background-repeat: no-repeat;
      content: '';
      opacity: 0;
      transition: 0.3s linear all;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &.Focused {
    border-color: $blue;
    box-shadow: 0 0 0 2px $blue-ring;

    &.Error {
      box-shadow: 0 0 0 2px $red-ring;
    }

    &.Warning {
      box-shadow: 0 0 0 2px $orange-ring;
    }
  }

  &.Error {
    border-color: $red;
  }

  &.Warning {
    border-color: $orange;
  }
}

.Input {
  overflow: auto;
  height: 250px;
  padding: 12px;
  background-color: $white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 13px;
  font-weight: 300;

  a {
    font-size: 13px;
    line-height: 14px;
    text-decoration: underline;
  }

  div:empty {
    display: none;
  }

  figure {
    display: inline-block;
    margin: 0 10px;
  }

  ul,
  ol {
    @apply pl-10;
    @apply mb-4;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  &.Disabled {
    @include disabled;
  }
}

.Input h1 {
  @include rtf-h1;
}

.Input h2 {
  @include rtf-h2;
}

.Input h3 {
  @include rtf-h3;
}

.Input h4 {
  @include rtf-h4;
}

.ButtonGroup {
  display: flex;
  height: 100%;
  align-items: center;
  border-left: 1px solid $medium-grey;

  &:first-child {
    border-left: 0;
  }

  &:first-child,
  &:nth-child(2) {
    .ButtonGroupItem {
      &:first-child,
      &:nth-child(2) {
        svg {
          path {
            fill: currentColor;
            stroke: none;
          }

          ellipse {
            fill: none;
            stroke: currentColor;
          }
        }
      }
    }
  }
}

.FileAttachmentButtton {
  float: right;

  .ButtonGroupItem {
    width: auto;
    padding: 0 15px;
    border-right: none;
    border-left: 1px solid $medium-grey;

    svg {
      transform: translateY(0);
    }
  }
}

.ButtonGroupItem {
  display: inline-flex;
  width: 40px;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: $dark-grey;
  cursor: pointer;
  text-align: center;

  &:hover {
    color: $blue;
  }

  svg {
    vertical-align: middle;

    path {
      fill: none;
      stroke: currentColor;
    }

    ellipse {
      fill: currentColor;
      stroke: none;
    }
  }

  &--active {
    color: $blue;
  }
}

.Button {
  display: inline-block;
  padding: 12px;
  border-right: 1px solid $medium-grey;
  cursor: pointer;
  transition: 0.3s ease-in-out opacity;

  &:hover {
    opacity: 0.7;
  }
}

.Editor {
  height: 200px;
  padding: 15px;
  overflow-y: scroll;
}

.LinkEditorBase {
  position: absolute;
  z-index: 99999;
  top: 34px;
  outline: none;
}

.LinkEditorOverlay {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0);
}

.LinkEditor {
  position: absolute;
  z-index: 9;
  display: flex;
  width: 360px;
  box-sizing: border-box;
  padding: $spacing-small;
  border: 1px solid $medium-grey;
  margin-left: -7px;
  background: $white;
  border-radius: 2px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  transform: translateX(-50%);

  &::after {
    position: absolute;
    top: -13px;
    left: 50%;
    width: 0;
    height: 0;
    border-right: $spacing-small solid transparent;
    border-bottom: $spacing-small solid $white;
    border-left: $spacing-small solid transparent;
    margin-left: -7px;
    content: '';
  }

  &::before {
    position: absolute;
    z-index: 2;
    top: -14px;
    left: 50%;
    width: 0;
    height: 0;
    border-right: 14px solid transparent;
    border-bottom: 14px solid $medium-grey;
    border-left: 14px solid transparent;
    margin-left: -8px;
    content: '';
  }

  &_Input {
    flex: 1;

    > div {
      margin: 0;
    }

    input {
      height: 48px;
    }
  }

  &_Button {
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    margin-left: $spacing-small;
    background: $blue;

    a {
      justify-content: center;
    }

    svg {
      * {
        stroke: $white;
      }
    }

    &:hover {
      background: $dim-blue;

      svg {
        * {
          stroke: $white;
        }
      }
    }

    &--disabled {
      background: $dark-grey;
      cursor: not-allowed;

      svg {
        * {
          stroke: $white;
        }
      }

      &:hover {
        background: $dark-grey;

        svg {
          * {
            stroke: $white;
          }
        }
      }
    }
  }
}

.StyleSelector {
  width: 150px;
}

.StyleMenu {
  position: absolute;
  z-index: 9;
  top: 40px;
  width: 150px;
  box-sizing: border-box;
  border: 1px solid $medium-grey;
  border-top: 0;
  background: white;
}

.StyleMenuItem {
  padding: 8px;
  border-top: 1px solid $medium-grey;
  cursor: pointer;

  &:hover {
    background: $off-white;
  }
}

.StyleMenuItem__Label {
  &--paragraph {
    @include rtf-paragraph;

    line-height: 18px;
  }

  &--header-one {
    @include rtf-h1;

    line-height: 18px;
  }

  &--header-two {
    @include rtf-h2;

    line-height: 18px;
  }

  &--header-three {
    @include rtf-h3;

    line-height: 18px;
  }

  &--header-four {
    @include rtf-h4;

    line-height: 18px;
  }
}

.StyleMenuTrigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacing-small;
  cursor: pointer;
  line-height: inherit;
}

.StyleMenuTriggerIcon {
  svg {
    vertical-align: middle;

    * {
      stroke: $dark-grey;
    }
  }

  &--isVisible {
    svg {
      * {
        stroke: $blue;
      }
    }
  }
}

.InsertImageText {
  margin-bottom: $spacing-small;
}

.HeadingWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  .Label {
    display: flex;
    min-height: 24px;
    align-items: center;

    .Required {
      margin-left: 4px;
      color: $red;
    }
  }
}

.HeadingOptional {
  position: relative;
  top: 3px;
  color: $blue-grey;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  text-transform: initial;
}
