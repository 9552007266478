@import '../../styles/variables';

.Wrapper {
  padding: 0 0 8px 0;
}

.Label {
  display: inline;
  padding: 0 8px 0 0;
  color: $blue-grey;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  white-space: normal;
}
