@import '../../styles/variables';

.TreeSelect {
  width: 350px;
  min-width: 200px;
  max-width: 350px;
  font-size: 13px;

  &.Error {
    :global(.ant-select-selection) {
      border-color: $red;
    }
  }

  :global(.ant-select-selection) {
    height: 40px;
    line-height: 40px;
  }

  :global(.ant-select-selection__rendered) {
    height: 40px;
    line-height: 40px;
  }

  :global(.ant-select-search) {
    height: 40px;
    line-height: 40px;
  }

  :global(.ant-select-selection__placeholder){
    color: $blue-grey;
  }
}

.Dropdown {
  z-index: 999999;
  overflow: auto;
  max-height: 400px !important;
}

.DropdownIcon {
  transform: translate(5px, -5px);
}

:global(.ant-select-open){
  .DropdownIcon {
    transform: rotate(180deg) translate(-5px, 4px);
    svg {
      * {
        stroke: $blue;
      }
    }
  }
}
