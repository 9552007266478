@import '../../styles/variables';

.Container {
  overflow: hidden;
}

.Grid {
  display: flex;
  flex-direction: column;
  margin: -5px;

  &.Vertical {
    flex-direction: row;
  }
}

.Cell {
  width: 100%;
  padding: 5px;
}

.Option {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  border: 1px solid $medium-grey;
  appearance: none;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;

  &:focus {
    border-color: $blue;
    outline: none;
  }

  &.Selected {
    border-color: $blue;
  }
}

.Image {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: 10px;
}

.Description {
  margin-top: 1px;
}

.Radio {
  display: flex;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: 1px solid $dark-blue-grey;
  margin-right: 10px;
  border-radius: 18px;

  &:after {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    content: '';
  }

  &.Right {
    margin-right: 10px;
    margin-left: 10px;
  }

  &.Selected {
    border-color: $blue;
    box-shadow: 0 0 0 2px $blue-ring;

    &:after {
      background-color: $blue;
    }
  }
}
