@import '../../styles/variables';

:global(.ant-input).Textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 11px $spacing-small;
  border-radius: $border-radius;
  color: $dark-blue;
  font-family: $font-family;
  font-size: 13px;
  line-height: 24px;
  outline: none;
  resize: none;
  transition: 0.2s ease-in-out all;

  &::placeholder {
    color: $blue-grey;
  }

  &:focus {
    border: none !important;
    margin-bottom: 0 !important;
  }
}

.CharactersRemaining {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 6px 2px 0;
  color: $blue-grey;
  font-size: 12px;
  font-weight: 500;
}
