@import '../../styles/variables';

.MarkdownEditorField {
  :global(.editor-toolbar) {
    border-top: 1px solid $medium-grey;
    border-right: 1px solid $medium-grey;
    border-left: 1px solid $medium-grey;
  }

  &--Disabled {
    opacity: 0.6;
  }

  .MarkdownEditor {
    position: relative;
  }
}
