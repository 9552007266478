@import '../../styles/variables';

.Container {
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: $spacing-large;
  }
}

h5.FilterHeader {
  margin-bottom: 4px;
  font-weight: 500;
}

.Select {
  margin-bottom: 0;
}

.Radio,
.Checkbox {
  display: block;
  height: 23px;
  margin: 0 !important;
  font-size: 13px;
  line-height: 23px;
}
