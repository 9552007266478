.Container {
  display: inline;
}

.Tag {
  display: inline-flex;
	height: 18px;
  align-items: center;
  padding: 0 4px;
  background-color: #E3EBF3;
	border-radius: 20px;
}