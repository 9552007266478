@import '../../../styles/variables';

.Container {
  display: flex;
}

.PDFSection {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
}
